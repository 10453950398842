import accordion from './components/accordion';

const init = () => {
  accordion();
};


document.addEventListener("DOMContentLoaded", function () {
  init();
});
